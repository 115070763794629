import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: '#E91E63',
                secondary: '#00BCD4',
                accent: '#5087e2',
                text: '#30302c'
            },
        },
    },
    icons: {
        iconfont: 'mdi',
    },
});