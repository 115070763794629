import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { title: 'Die Webmacherin – Modernes Webdesign aus Vorarlberg' },
    component: Home
  },
  {
    path: '/referenzen',
    name: 'referenzen',
    meta: { title: 'Die Webmacherin – Referenzen Webseiten & Webshops' },
    component: () => import('../views/References.vue')
  },
  {
    path: '/workshops',
    name: 'workshops',
    meta: { title: 'Die Webmacherin – Workshops zu Webdesign, HTML & CSS' },
    component: () => import('../views/Workshops.vue')
  },
  {
    path: '/ueber-mich',
    name: 'ueber-mich',
    meta: { title: 'Die Webmacherin – Über mich' },
    component: () => import('../views/About.vue')
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    meta: { title: 'Die Webmacherin – Kontakt' },
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/nachhaltigkeit',
    name: 'nachhaltigkeit',
    meta: { title: 'Die Webmacherin – Nachhaltigkeit' },
    component: () => import('../views/Sustainability.vue')
  },
  {
    path: '/webdesign-mit-wordpress',
    name: 'webdesign-mit-wordpress',
    meta: { title: 'Die Webmacherin – Webdesign mit Wordpress' },
    component: () => import('../views/WebdesignWordpress.vue')
  },
  {
    path: '/template',
    name: 'template',
    // route level code-splitting
    // this generates a separate chunk (template.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "template" */ '../views/Template.vue')
  },
  {
    path: '/404',
    component: () => import('../views/404.vue')
  },  
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: function(to) {
        let scrollTarget = 0
        let scrollOptions = {
              duration: 500,
              offset: 100,
              easing: 'easeInOutCubic',
        }
        if (to.hash) {
            scrollTarget = to.hash
        }
        return goTo(scrollTarget, scrollOptions)
    },
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
});

export default router
