<template>
    <div class="footer-content">
        <a id="kontakt"></a>
        <v-container class="contact">
            <v-row>
                <v-col cols="10" offset="1">
                    <v-card class="patterned" color="amber" elevation="0" tile>
                        <v-card-text class="text-center white--text">
                            <h2 class="subtitle-2 text-uppercase">Kontakt</h2>
                            <h3 :class="$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2'">So kannst Du mich erreichen:</h3>
                            <p class="title font-family-body font-weight-regular">
                                <span class="font-weight-bold">Sara Geller</span><br/>
                                <span class="font-weight-bold">Email:</span> hallo@die-webmacherin.at<br/>
                                <span class="font-weight-bold">Telefon:</span> +43 (0)677 61 3344 87
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-footer color="pink">
            <v-container class="fill-height mb-15 mb-sm-0">
                <v-row align-content="end" class="fill-height text-center">
                    <v-col cols="12" md="3" order-md="1">
                        <v-dialog v-model="imprintModal" width="1200px">
                            <template v-slot:activator="{ on }">
                                <v-btn color="white" text x-large v-on="on">Impressum</v-btn>
                            </template>

                            <v-card>
                                <v-card-title>
                                    <p>Impressum</p>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="imprintModal = false" icon>
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-card-title>

                                <v-card-text>
                                    <p class="title">Die Webmacherin</p>
                                    <p>
                                        Sara Geller<br/>
                                        Einzelunternehmerin<br/>
                                        <br/>
                                        Heldenstraße 17<br/>
                                        6800 Feldkirch<br/>
                                        Österreich<br/>
                                        <strong>Achtung: Dies ist keine Büroadresse! Du kannst leider nicht ohne Termin vorbei kommen.</strong>
                                    </p>
                                    <p>
                                        Email: hallo@die-webmacherin.at<br/>
                                        Telefon: +43 677 61 3344 87<br/>
                                        Website: www.die-webmacherin.at
                                    </p>
                                    <p>
                                        <strong>Verantwortlich für den Inhalt:</strong><br/>
                                        Sara Geller
                                    </p>
                                    <p>
                                        <strong>Unternehmensgegenstand</strong><br/>
                                        Werbeagentur
                                    </p>
                                    <p>
                                        <strong>UID-Nummer</strong><br/>
                                        ATU75143649
                                    </p>
                                    <p>
                                        <strong>Zuständige Aufsichtsbehörde</strong><br/>
                                        Bezirkshauptmannschaft Feldkirch
                                    </p>
                                    <p>
                                        <strong>Zuständige Kammer</strong><br/>
                                        Wirtschaftskammer Vorarlberg
                                    </p>
                                    <p>
                                        Berufsrechtliche Regelungen Gewerbeordnung, <a href="https://www.ris.bka.gv.at/" target="_blank">www.ris.bka.gv.at</a>
                                    </p>
                                    <p>
                                        Verbraucher haben die Möglichkeit, Beschwerden an die Online-Streitbeilegungsplattform der EU-Kommission zu richten: <a href="https://ec.europa.eu/odr" target="_blank">https://ec.europa.eu/odr</a>.<br/>
                                        Sie können allfällige Beschwerden auch an die oben angegebene E-Mail-Adresse richten.
                                    </p>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="imprintModal = false" color="primary" class="mb-5 mr-4" x-large>
                                        Schließen
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" md="3" order-md="3">
                        <v-dialog v-model="privacyModal" width="1200px">
                            <template v-slot:activator="{ on }">
                                <v-btn color="white" text x-large v-on="on">Datenschutz</v-btn>
                            </template>

                            <v-card>
                                <v-card-title>
                                    <p>Datenschutz</p>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="privacyModal = false" icon>
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-card-title>
                                <v-card-text>
                                    <v-expansion-panels>
                                        <v-expansion-panel
                                            v-for="(paragraph ,i) in privacyParagraphs"
                                            :key="i"
                                        >
                                            <v-expansion-panel-header class="title">{{paragraph.title}}</v-expansion-panel-header>
                                            <v-expansion-panel-content><div v-html="paragraph.text"></div></v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="privacyModal = false" color="primary" class="mb-5 mr-4" x-large>
                                        Schließen
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" md="6" order-md="2" class="text-center">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-dialog v-model="ceseModal" width="1200px">
                                    <template v-slot:activator="{ on }">
                                        <v-img
                                            alt="CESE - Certified Ecommerce & Social Media Expert"
                                            class="mx-auto mt-3 img-link"
                                            contain
                                            max-height="110"
                                            :src="require('@/assets/images/certificates/zertifizierung_cese.png')"
                                            v-on="on"
                                        ></v-img>
                                    </template>

                                    <v-card>
                                        <v-card-title>
                                            <p>CESE - Certified Ecommerce & Social Media Expert</p>
                                            <v-spacer></v-spacer>
                                            <v-btn @click="ceseModal = false" icon>
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-img
                                                alt="Zertifikat - CESE - Certified Ecommerce & Social Media Expert"
                                                contain
                                                max-width="1200"
                                                :src="require('@/assets/images/certificates/CESE-Zertifikat_de.webp')"
                                            ></v-img>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn @click="ceseModal = false" color="primary" class="mb-5 mr-4" x-large>
                                                Schließen
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-dialog v-model="montafonModal" width="1200px">
                                    <template v-slot:activator="{ on }">
                                        <v-img
                                            alt="Marketingkooperationspartnerin von Tourismus Montafon"
                                            class="mx-auto mt-3 img-link"
                                            contain
                                            max-height="110"
                                            :src="require('@/assets/images/team/partner-tourismus-montafon_weiss.svg')"
                                            v-on="on"
                                        ></v-img>
                                    </template>

                                    <v-card>
                                        <v-card-title>
                                            <p>Marketingkooperationspartnerin von Tourismus Montafon</p>
                                            <v-spacer></v-spacer>
                                            <v-btn @click="montafonModal = false" icon>
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-img
                                                alt="Urkunde Marketingkooperationspartnerin von Tourismus Montafon"
                                                contain
                                                max-width="1200"
                                                :src="require('@/assets/images/certificates/partnerurkunde-tourismus-montafon.webp')"
                                            ></v-img>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn @click="montafonModal = false" color="primary" class="mb-5 mr-4" x-large>
                                                Schließen
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                        </v-row>
                        <a
                            href="https://all-inkl.com/info/umweltschutz/"
                            rel="noopener noreferrer"
                            target="_blank"
                            title="ALL-INKL.COM Ökostrom Hosting"
                        >
                            <v-img
                                alt="ALL-INKL.COM Ökostrom Hosting"
                                class="mx-auto mt-3"
                                contain
                                max-width="160"
                                :src="require('@/assets/images/all-inkl-banner-oekostrom_320x115.webp')"
                            ></v-img>
                        </a>
                    </v-col>
                </v-row>
            </v-container>
        </v-footer>
        <back-to-top/>
    </div>
</template>

<script>
import BackToTop from "./BackToTop.vue";

export default {
    name: "Footer",
    components: {
        BackToTop
    },
    data: () => ({
        ceseModal: false,
        montafonModal: false,
        imprintModal: false,
        privacyModal: false,
        privacyParagraphs:  [
            {
                title: 'Präambel',
                text: '\
                    <p>Der Schutz Ihrer Privatsphäre und Ihrer Daten ist uns sehr wichtig und wird stets bei allen geschäftlichen Transaktionen berücksichtigt.</p>\
                    <p>Bei Nutzung unserer Dienste erklären Sie sich mit der Nutzung Ihrer personenbezogenen Daten (i.F. “Daten” genannt) wie in dieser Datenschutzerklärung beschrieben einverstanden. Sämtliche personenbezogenen Bezeichnungen sind geschlechtsneutral zu verstehen.</p>\
                    <p>Grundsätzlich können Sie unsere Website ohne Angaben von personenbezogenen Daten benutzen. Für einzelne Services können allerdings andere Regelungen zutreffen, auf die wir Sie im Nachfolgenden gesondert hinweisen.</p>\
                '
            },
            {
                title: 'Allgemeines',
                text: '\
                    <p>Zweck der Verarbeitung Ihrer personenbezogenen Daten (i.F. “Daten” genannt) ist die Zurverfügungstellung von Informationen zu unserer Online-Agentur und der Darstellung und dem Angebot von Dienstleistungen zu diesem Thema. In dieser Datenschutzerklärung informieren wir Sie u.a. über</p>\
                    <ul>\
                        <li>den Namen und die Kontaktdaten der Verantwortlichen</li>\
                        <li>alle Zwecke, für die Ihre Daten verarbeitet werden</li>\
                        <li>die Rechtsgrundlagen, auf welchen die Verarbeitungstätigkeiten beruhen, inkl. eventuell unser berechtigtes Interesse</li>\
                        <li>alle Empfänger Ihrer Daten</li>\
                        <li>eine eventuelle Übermittlung Ihrer Daten in ein Drittland und die Darstellung der Rechtsgrundlage hierfür</li>\
                        <li>die Speicherdauer Ihrer Daten bzw. die Kriterien für die Festlegung der Dauer</li>\
                        <li>die Kategorien Ihrer Daten, die verarbeitet werden</li>\
                        <li>die Herkunft Ihrer Daten</li>\
                        <li>die Rechte der Betroffenen</li>\
                    </ul>\
                '
            },
            {
                title: 'Verantwortlich für den Datenschutz ist',
                text: '\
                    <p>Die Webmacherin, Sara Geller, Heldenstraße 17, 6800 Feldkirch, Österreich, Telefon: +43 677 61 3344 87, Email: hallo@die-webmacherin.at</p>\
                    <p>Es ist keine Datenschutzbeauftragte bestellt, da dies gesetzlich nicht erforderlich ist.</p>\
                '
            },
            {
                title: 'Ihre Rechte',
                text: '\
                    <p>Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:</p>\
                    <ul class="mb-3">\
                        <li>Recht auf Auskunft</li>\
                        <li>Recht auf Berichtigung oder Löschung</li>\
                        <li>Recht auf Einschränkung der Verarbeitung</li>\
                        <li>Recht auf Widerspruch gegen die Verarbeitung</li>\
                        <li>Recht auf Datenübertragbarkeit</li>\
                        <li>Recht auf Widerruf Ihrer Einwilligung</li>\
                    </ul>\
                    <p>Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschweren.</p>\
                    <p>Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.</p>\
                    <p class="subtitle-2">Recht auf Auskunft</p>\
                    <p>Sie haben das Recht, jederzeit von uns unentgeltliche Auskunft über die zu Ihrer Person gespeicherten personenbezogenen Daten und eine Kopie dieser Auskunft zu erhalten, dabei über</p>\
                    <ul class="mb-3">\
                        <li>die Verarbeitungszwecke</li>\
                        <li>die Kategorien personenbezogener Daten, die verarbeitet werden</li>\
                        <li>die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder noch offengelegt werden, insbesondere bei Empfängern in Drittländern oder bei internationalen Organisationen</li>\
                        <li>falls möglich die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer</li>\
                        <li>das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch uns oder eines Widerspruchsrechts gegen diese Verarbeitung</li>\
                        <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde</li>\
                        <li>wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden: Alle verfügbaren Informationen über die Herkunft der Daten</li>\
                        <li>das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Artikel 22 Abs.1 und 4 DSGVO und — zumindest in diesen Fällen — aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.</li>\
                    </ul>\
                    <p>Ferner steht Ihnen ein Auskunftsrecht darüber zu, ob personenbezogene Daten an ein Drittland oder an eine internationale Organisation übermittelt wurden. Sofern dies der Fall ist, so steht Ihnen im Übrigen das Recht zu, Auskunft über die geeigneten Garantien im Zusammenhang mit der Übermittlung zu erhalten.</p>\
                    <p class="subtitle-2">Recht auf Berichtigung</p>\
                    <p>Sie haben das Recht, die unverzügliche Berichtigung betreffender unrichtiger personenbezogener Daten zu verlangen.</p>\
                    <p>Ferner steht Ihnen das Recht zu, unter Berücksichtigung der Zwecke der Verarbeitung, die Vervollständigung unvollständiger personenbezogener Daten — auch mittels einer ergänzenden Erklärung — zu verlangen.</p>\
                    <p class="subtitle-2">Recht auf Löschung</p>\
                    <p>Sie haben das Recht, von uns zu verlangen, dass Ihre betreffenden personenbezogenen Daten unverzüglich gelöscht werden, sofern einer der folgenden Gründe zutrifft und soweit die Verarbeitung nicht erforderlich ist:</p>\
                    <ul class="mb-3">\
                        <li>Die personenbezogenen Daten wurden für solche Zwecke erhoben oder auf sonstige Weise verarbeitet, für welche sie nicht mehr notwendig sind.</li>\
                        <li>Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gemäß Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.</li>\
                        <li>Sie legen gemäß Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein, und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gemäß Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.</li>\
                        <li>Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>\
                        <li>Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.</li>\
                        <li>Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.</li>\
                    </ul>\
                    <p class="subtitle-2">Recht auf Vergessen werden</p>\
                    <p>Wurden die personenbezogenen Daten von uns öffentlich gemacht und ist unser Unternehmen als Verantwortlicher gemäß Art. 17 Abs. 1 DSGVO zur Löschung der personenbezogenen Daten verpflichtet, so trifft uns unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um andere Verantwortliche, welche die veröffentlichten personenbezogenen Daten verarbeiten, darüber in Kenntnis zu setzen, dass Sie von diesen anderen Verantwortlichen die Löschung sämtlicher Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben, soweit die Verarbeitung nicht erforderlich ist.</p>\
                    <p class="subtitle-2">Recht auf Einschränkung der Verarbeitung</p>\
                    <p>Sie haben das Recht, von uns die Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:</p>\
                    <ul class="mb-3">\
                        <li>Die Richtigkeit der personenbezogenen Daten wird von Ihnen bestritten, und zwar für eine Dauer, die es uns ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen.</li>\
                        <li>Die Verarbeitung ist unrechtmäßig, Sie lehnen die Löschung der personenbezogenen Daten ab und verlangen stattdessen die Einschränkung der Nutzung der personenbezogenen Daten.</li>\
                        <li>Wir benötigen die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger, Sie benötigen sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</li>\
                        <li>Sie haben Widerspruch gegen die Verarbeitung gem. Art. 21 Abs. 1 DSGVO eingelegt und es steht noch nicht fest, ob unsere berechtigten Gründe gegenüber den Ihren überwiegen.</li>\
                    </ul>\
                    <p class="subtitle-2">Recht auf Datenübertragbarkeit</p>\
                    <p>Sie haben das Recht, Ihre betreffenden personenbezogenen Daten, welche durch uns bereitgestellt wurden, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten.</p>\
                    <p>Sie haben außerdem das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns zu übermitteln, sofern die Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gemäß Art. 6 Abs. 1 lit. b DSGVO beruht und die Verarbeitung mithilfe automatisierter Verfahren erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, welche uns übertragen wurde.</p>\
                    <p>Ferner haben Sie bei der Ausübung ihres Rechts auf Datenübertragbarkeit das Recht zu erwirken, dass die personenbezogenen Daten direkt von uns an einen anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und sofern hiervon nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden.</p>\
                    <p class="subtitle-2">Recht auf Widerspruch</p>\
                    <p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Ihrer betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen. Dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.</p>\
                    <p>Wir verarbeiten die personenbezogenen Daten im Falle des Widerspruchs nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die den Interessen, Rechten und Freiheiten von Ihnen überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>\
                    <p>Verarbeiten wir personenbezogene Daten, um Direktwerbung zu betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen. Dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.</p>\
                    <p>Widersprechen Sie uns, für Zwecke bei der Verarbeitung der Direktwerbung, so werden wir Ihre personenbezogenen Daten nicht mehr für diese Zwecke verarbeiten.</p>\
                    <p>Zudem haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die Verarbeitung personenbezogener Daten, die bei uns zu wissenschaftlichen oder historischen Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89 Abs. 1 DSGVO erfolgen, Widerspruch einzulegen, es sei denn, eine solche Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse liegenden Aufgabe erforderlich.</p>\
                    <p class="subtitle-2">Automatisierte Entscheidungen im Einzelfall einschließlich Profiling</p>\
                    <p>Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfalten oder Sie in ähnlicher Weise erheblich beeinträchtigt, sofern die Entscheidung (1) nicht für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und uns erforderlich ist, oder (2) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen wir unterliegen, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder (3) mit Ihrer ausdrücklicher Einwilligung erfolgt.</p>\
                    <p class="subtitle-2">Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</p>\
                    <p>Sie haben das Recht, eine Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu widerrufen. Durch Ihren Widerruf wird die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung aber nicht berührt.</p>\
                '
            },
            {
                title: 'Minderjährige',
                text: '\
                    <p>Unsere Website und unsere Services sind nicht zur Nutzung durch Minderjährige bestimmt, und wir wollen keine Daten von Minderjährigen erheben. Ist ein Elternteil oder ein Vormund eines Minderjährigen der Auffassung, dass sein Kind uns gegenüber personenbezogene Daten angegeben haben könnte, so schreiben Sie uns an die nachstehend angegebenen Kontaktmöglichkeiten, und wir werden diese personenbezogenen Daten dann vorbehaltlich geltendem Recht und dieser Policy löschen.</p>\
                    <p>Die Webmacherin, Sara Geller, Heldenstraße 17, 6800 Feldkirch, Österreich, Telefon: +43 677 61334487, Email: hallo@die-webmacherin.at</p>\
                '
            },
            {
                title: 'Datensicherheit',
                text: '\
                    <p>Wir verwenden angemessene technische und organisatorische Maßnahmen und Sicherheitsvorkehrungen (TOMs), die dazu dienen, den unberechtigten Zugriff, die rechtswidrige Verarbeitung und den unbefugten oder zufälligen Verlust Ihrer Daten zu verhindern.</p>\
                    <p>Dazu gehört beispielsweise die Verschlüsselung Ihrer Kommunikation mit uns über diese Website basierend auf dem Verschlüsselungsprotokoll Secure Socket Layer (SSL).</p>\
                    <p>Sie können die Qualität unserer Verschlüsselung hier prüfen: <a href="https://www.ssllabs.com/ssltest" target="_blank">https://www.ssllabs.com/ssltest</a></p>\
                    <p>Es ist uns wichtig darauf hinzuweisen, dass die Datenübertragung im Internet Sicherheitslücken aufweisen kann, da ein lückenloser Schutz vor dem Zugriff durch unbefugte Dritte nicht möglich ist.</p>\
                '
            },
            {
                title: 'Webhost',
                text: '\
                    <p>Der Server, auf dem diese Website gehosted wird, befindet sich in Deutschland und wird von ALL-INKL.COM - Neue Medien Münnich, René Münnich, Hauptstraße 68, 02742 Friedersdorf, Deutschland, betrieben, mit welcher wir, die Die Webmacherin, Sara Geller (Österreich) einen Sub-Auftragsverarbeitungsvertrag abgeschlossen haben.</p>\
                    <p>Genaueres entnehmen Sie bitte <a href="https://all-inkl.com/datenschutzinformationen/" target="_blank">https://all-inkl.com/datenschutzinformationen/</a></p>\
                '
            },
            {
                title: 'Server-Log-Files',
                text: '\
                    <p>Unsere Website erfasst mit jedem Ihrer Aufrufe durch ein automatisiertes System eine Reihe von allgemeinen Daten und Informationen.</p>\
                    <p>Diese allgemeinen Daten und Informationen werden in den Logfiles unseres Servers von Uberspace in Deutschland gespeichert.</p>\
                    <p class="subtitle-2">Erfasst werden können</p>\
                    <ul class="mb-3">\
                        <li>die verwendeten Browsertypen und Versionen</li>\
                        <li>das vom zugreifenden System verwendete Betriebssystem</li>\
                        <li>die Website, von welcher ein zugreifendes System auf unsere Website gelangt (sogenannte Referrer)</li>\
                        <li>die Unterwebseiten, welche über ein zugreifendes System auf unserer Website angesteuert werden</li>\
                        <li>das Datum und die Uhrzeit eines Zugriffs auf die Website</li>\
                        <li>eine Internet-Protokoll-Adresse (IP-Adresse)</li>\
                        <li>der Internet-Service-Provider des zugreifenden Systems</li>\
                        <li>sonstige ähnliche Daten und Informationen, die der Gefahrenabwehr im Falle von Angriffen auf unsere informationstechnologischen Systeme dienen.</li>\
                    </ul>\
                    <p>Bei der Nutzung dieser allgemeinen Daten und Informationen ziehen wir keine Rückschlüsse auf Sie.</p>\
                    <p class="subtitle-2">Diese Informationen werden benötigt, um</p>\
                    <ul class="mb-3">\
                        <li>die Inhalte unserer Website korrekt auszuliefern</li>\
                        <li>die Inhalte unserer Website sowie die Werbung für diese zu optimieren</li>\
                        <li>die dauerhafte Funktionsfähigkeit unserer informationstechnologischen Systeme und der Technik unserer Website zu gewährleisten</li>\
                        <li>Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur Strafverfolgung notwendigen Informationen bereitzustellen.</li>\
                    </ul>\
                    <p>Diese anonym erhobenen Daten und Informationen werten wir einerseits statistisch und ferner mit dem Ziel aus, den Datenschutz und die Datensicherheit in unserem Unternehmen zu erhöhen, um letztlich ein optimales Schutzniveau für Sie sicherzustellen. Wir dürfen diese Daten jedenfalls aufgrund unseres berechtigten Interesses nach Art 6 Abs 1 lit f DSGVO verarbeiten.</p>\
                    <p>Die Protokolle werden getrennt von allen durch Sie angegebenen personenbezogenen Daten gespeichert und außerdem nach maximal 2 Monaten gelöscht.</p>\
                '
            },
            {
                title: 'Cookies',
                text: `
                    <p>Auf dieser Webseite werden keine Cookies gesetzt und keinerlei Daten gesammelt.</p>
                `
            },
            {
                title: 'Wie erheben wir Ihre Daten?',
                text: '\
                    <p>Zum aktuellen Zeitpunkt können Sie uns ein Email senden, uns telefonisch oder persönlich kontaktieren. Beachten Sie bitte, dass unverschlüsselte E-Mails, die über das Internet versendet werden, nicht hinreichend vor der unbefugten Kenntnisnahme durch Dritte geschützt sind.</p>\
                '
            },
            {
                title: 'Warum verarbeiten wir Ihre Daten?',
                text: '\
                    <p>Wir verarbeiten Ihre Daten, damit wir unsere Dienstleistungen erbringen und verrechnen können. Ihre Daten können von uns im Rahmen der Geschäftsbeziehung mit Ihnen zu den Zwecken der Büroverwaltung, des Controllings, des Rechnungswesens sowie der Kundenbetreuung verarbeitet werden.</p>\
                    <p>Wenn Sie uns kontaktieren, um z.B. lediglich eine Auskunft von uns zu erhalten, verarbeiten wir Ihre Daten zu diesem Zweck. Wenn Sie uns kontaktieren, um z.B. einen Vertrag abzuschließen, verarbeiten wir Ihre Daten zu diesem Zweck.</p>\
                    <p class="subtitle-2">Zur Abwicklung Ihres Auftrages, einschließlich Kundenbetreuung</p>\
                    <p>Wenn Sie uns Ihre Auftragsdaten telefonisch, via Email, persönlich oder auf sonstige Weise bekanntgeben, werden die von Ihnen angegebenen Daten, einschließlich Ihrer personenbezogenen Daten zum Zweck der Auftragsabwicklung im Rahmen unserer Geschäftsbeziehung mit Ihnen von uns und den unten genannten Empfängern zur (Vor-)Vertragsabwicklung verarbeitet, um Ihren Auftrag abzuwickeln und zu verwalten, sowie um Sie als Kunde zu betreuen.</p>\
                '
            },
            {
                title: 'Warum dürfen wir Ihre Daten verarbeiten?',
                text: '\
                    <p>Wenn Sie uns kontaktieren, um z.B. lediglich eine Auskunft von uns zu erhalten, dürfen wir Ihre Daten aufgrund Ihrer Einwilligung nach Art 6 Abs 1 lit a DSGVO bzw. des Art 6 Abs 1 lit f DSGVO verarbeiten. Wenn Sie uns kontaktieren, um z.B. einen Vertrag abzuschließen, dürfen wir Ihre Daten aufgrund des Art 6 Abs 1 lit b DSGVO verarbeiten sowie allenfalls aufgrund des Art 6 Abs 1 lit c DSGVO speichern.</p>\
                    <p class="subtitle-2">Zur Abwicklung Ihres Auftrages</p>\
                    <p>Die Verarbeitung Ihrer Daten, einschließlich der von Ihnen angegebenen personenbezogenen Daten sowie allenfalls unaufgefordert und freiwillig angegebenen besonderen Kategorien personenbezogener Daten erfolgt durch uns und die unten angeführten Empfänger auf der Rechtsgrundlage des Art. 6 Abs. 1 S. 1 lit. b und Art. 9 Abs. 2 lit. a. DSGVO, um Sie als Kunde identifizieren zu können, um den gegenständlichen Auftrag angemessen abwickeln zu können, sowie zur Korrespondenz mit Ihnen. Die Datenverarbeitung erfolgt auf Ihre Anfrage hin und ist zu den genannten Zwecken für die angemessene Bearbeitung Ihres Auftrages erforderlich.</p>\
                    <p class="subtitle-2">Marketing (generell)</p>\
                    <p>Berechtigtes Interesse Art. 6 Abs. 1 S. 1 lit. f DSGVO. Das berechtigte Interesse ist unser Interesse an der Geschäftsanbahnung und Intensivierung der Geschäftsbeziehung mit bestehenden und potentiellen Kunden.</p>\
                '
            },
            {
                title: 'Welche Daten verarbeiten wir?',
                text: '\
                    <p class="subtitle-2">Auskunft</p>\
                    <p>Wenn Sie uns kontaktieren, um lediglich eine Auskunft von uns zu erhalten, verarbeiten wir die von Ihnen angegebenen Daten.</p>\
                    <p>Die Bereitstellung von Informationen erfolgt Ihrerseits auf rein freiwilliger Basis. Dennoch bitten wir Sie ausdrücklich darum, uns keine Daten offenzulegen, die wahrscheinlich nur eine geringe oder keine Relevanz für den von Ihnen beabsichtigten Zweck haben; dies gilt insbesondere für besondere Kategorien personenbezogener (“sensible”) Daten.</p>\
                    <p class="subtitle-2">Auftrag</p>\
                    <p>Je nachdem, was Sie uns freiwillig zur Verfügung stellen, können Ihre durch uns verarbeiteten Daten Folgendes enthalten:</p>\
                    <ul class="mb-3">\
                        <li>Ihre Kontaktdaten (Name, Anschrift, Telefonnummer, E-Mail-Adresse usw.)</li>\
                        <li>Inhalt des Auftrages</li>\
                        <li>uns gegenüber durch Sie unaufgefordert und freiwillig angegebene besondere Kategorien personenbezogener Daten</li>\
                    </ul>\
                    <p>Die Bereitstellung von Informationen erfolgt Ihrerseits auf rein freiwilliger Basis. Dennoch bitten wir Sie ausdrücklich darum, uns keine Daten offenzulegen, die wahrscheinlich nur eine geringe oder keine Relevanz für den von Ihnen beabsichtigten Zweck haben; dies gilt insbesondere für besondere Kategorien personenbezogener (“sensible”) Daten.</p>\
                '
            },
            {
                title: 'An wen werden Ihre Daten weitergegeben?',
                text: '\
                    <p>Ihre Daten können zur Gänze oder zum Teil, jedoch immer nur in dem Ausmaß und soweit erforderlich, weitergegeben werden an folgende Verantwortliche:</p>\
                    <ul class="mb-3">\
                        <li>Banken (Zahlungsverkehr – Österreich)</li>\
                        <li>Steuerberater (Rechnungslegung – Österreich)</li>\
                        <li>Inkassobüros (Forderungseintreibung – Österreich)</li>\
                        <li>Rechtsvertreter (Rechtsdurchsetzung – Österreich)</li>\
                        <li>Gerichte (Rechtsdurchsetzung – Österreich)</li>\
                        <li>Verwaltungsbehörden (Österreich)</li>\
                    </ul>\
                    <p>Darüber hinaus können Ihre Daten an folgende Empfänger als Auftragsverarbeiter weitergegeben werden; mit sämtlichen haben wir einen Auftragsverarbeitungsvertrag abgeschlossen und die angemessenen technischen und organisatorischen Maßnahmen (TOMs) verifiziert:</p>\
                    <ul class="mb-3">\
                        <li>ALL-INKL.COM - Neue Medien Münnich (Webhosting, Webmail – Deutschland)</li>\
                        <li>Atlassian Pty Ltd (Projektmanagementsoftware – USA – Data Residency Europe)</li>\
                        <li>FreeFinance (Buchhaltungssoftware – Österreich)</li>\
                        <li>meetergo GmbH (Terminbuchungssoftware – Deutschland)</li>\
                        <li>Mega.nz (Cloud-Speicher – Neuseeland – Sicheres Drittland)</li>\
                        <li>Sabrina Erhard - Büromy (Buchhalterin – Österreich)</li>\
                        <li>Trias Wirtschaftstreuhand GmbH (Wirtschaftsprüfungs- und Steuerberatungsgesellschaft – Österreich)</li>\
                    </ul>\
                '
            },
            {
                title: 'Wie lange verarbeiten wir Ihre Daten?',
                text: '\
                    <p>Ihre Daten werden in einer Form gespeichert, die Ihre Identifizierung nur so lange ermöglicht, wie es für den Zweck, für den sie verarbeitet werden, erforderlich ist.</p>\
                    <p class="subtitle-2">Auskunft</p>\
                    <p>Indem Sie uns Ihre Daten angeben, willigen Sie ausdrücklich ein, dass Ihre Daten, einschließlich der von Ihnen angegebenen personenbezogenen Daten sowie allenfalls unaufgefordert und freiwillig angegebenen besonderen Kategorien personenbezogener Daten für die Dauer der Abwicklung der gegenständlichen Auskunft von uns und den oben angeführten Empfängern verarbeitet werden.</p>\
                    <p>Wenn Sie uns kontaktieren, um lediglich eine Auskunft von uns zu erhalten, werden Ihre Daten daher je nach Inhalt der Kommunikation entweder unverzüglich gelöscht oder nach Ablauf einer dem Inhalt entsprechenden, angemessenen Frist gelöscht.</p>\
                    <p>Bei Widerruf Ihrer Einwilligungserklärung werden wir sämtliche Ihrer - auch kumulierten - Daten aus sämtlichen Datenbanken löschen (lassen).</p>\
                    <p class="subtitle-2">Auftrag</p>\
                    <p>Wir sind aufgrund handels- und steuerrechtlicher Vorgaben verpflichtet, Ihre Adress-, Zahlungs- und Auftragsdaten für die Dauer von 7 Jahren zu speichern. Wenn Sie uns kontaktieren, um einen Vertrag abzuschließen, werden die Daten am Ende des 7. Jahres nach Verbuchung des letzten Beleges gelöscht (§ 132 BAO). Im Falle eines Vertragsabschlusses werden daher sämtliche Daten aus dem Vertragsverhältnis bis zum Ablauf dieser Frist gespeichert.</p>\
                    <p>Gesetzliche/rechtliche Aufbewahrungspflichten oder vertragliche Verpflichtungen z.B. gegenüber Kunden aus Gewährleistung oder Schadenersatz oder gegenüber Vertragspartnern sind eine weitere Grundlage, Ihre Daten weiterhin zu speichern. (Art. 6 Abs. 1 S. 1 lit. c DSGVO; Art. 17 Abs. 3 lit. e DSGVO).</p>\
                    <p class="subtitle-2">Marketing (generell)</p>\
                    <p>Marketingdaten werden bis 3 Jahre nach dem letzten Kontakt aufbewahrt.</p>\
                '
            },
        ]
    })
};
</script>
