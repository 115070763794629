<template>
    <v-scale-transition>
        <v-btn
            aria-label="Nach oben scrollen"
            class="btt-button"
            color="primary"
            fab
            v-scroll="onScroll"
            v-show="isVisible"
            @click="$vuetify.goTo(0, scrollOptions)"
        >
            <v-icon size="35">mdi-chevron-up</v-icon>
        </v-btn>
    </v-scale-transition>
</template>
<style lang="scss">
    .btt-button {
        position: fixed;
        bottom: 20px;
        right: 10px;
        z-index: 5;
    }
</style>

<script>
export default {
    name: "BackToTop",
    data: () => ({
        isVisible: false
    }),
    computed: {
      scrollOptions () {
        return {
          duration: 700,
          offset: 0,
          easing: 'easeInOutCubic',
        }
      },
    },
    methods: {
        onScroll (e) {
            if (typeof window === 'undefined') return
            const top = window.pageYOffset ||   e.target.scrollTop || 0
            this.isVisible = top > 150
        }
    }
};
</script>
