import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './styles/custom.scss'
import router from './plugins/router.js'
//import VueMatomo from 'vue-matomo'

Vue.config.productionTip = false

/*Vue.use(VueMatomo, {
    host: 'https://diewebmacherin.matomo.cloud/',
    siteId: 1
});*/

new Vue({
  vuetify,
  router,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')

//window._paq.push(['trackPageView']); // To track a page view