<template>
<div>
    <v-app-bar
        app
        color="pink"
        dark
        elevate-on-scroll
        fade-img-on-scroll
    >
        <template v-slot:img="{ props }">
            <v-img
                v-bind="props"
                alt="Die Webmacherin Logo"
            ></v-img>
        </template>

        <v-toolbar-title
            class="align-self-start"
            :class="$vuetify.breakpoint.mdAndDown ? 'py-2' : 'py-1'"
        >
            <router-link to="/">
                <v-img
                    alt="Die Webmacherin Logo"
                    contain
                    :height="$vuetify.breakpoint.mdAndDown ? 30 : 40"
                    :src="require('@/assets/webmacherin-logo_weiss.png')"
                    :width="$vuetify.breakpoint.mdAndDown ? 288 : 383"
                />
            </router-link>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-app-bar-nav-icon
            @click.stop="mainNavDrawer = !mainNavDrawer"
            v-if="$vuetify.breakpoint.mdAndDown"
            aria-label="Hauptmenü öffnen"
        ></v-app-bar-nav-icon>
        <template v-if="$vuetify.breakpoint.lgAndUp">
            <v-btn
                v-for="(item, i) in mainNavItems"
                :key="i"
                class="my-1"
                color="white" text large
                :to="item.target"
            >
                {{item.name}}
            </v-btn>
        </template>
    </v-app-bar>
    <v-navigation-drawer
        app
        clipped
        color="primary"
        dark
        fixed
        temporary
        v-model="mainNavDrawer"
        width="100%"
    >
        <template v-slot:img>
            <v-img
                :src="require('@/assets/dots-halftone2-2-wide_opacity3.svg')"
                class="custom-pattern"
            ></v-img>
        </template>
        <template v-slot:prepend>
            <v-row>
                <v-spacer></v-spacer>
                <v-col class="flex-grow-0 mr-3">
                    <v-btn @click="mainNavDrawer = false" icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </template>
        <v-list
            nav
        >
            <v-list-item-group
                class="text-center"
            >
                <v-list-item
                    v-for="(item, i) in mainNavItems"
                    :key="i"
                    :to="item.target"
                >
                    <v-list-item-title>{{item.name}}</v-list-item-title>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
</div>
</template>

<script>
export default {
    name: "Header",
    data: () => ({
        mainNavDrawer: false,
        mainNavItems:   [
            {
                name: 'Referenzen',
                target: '/referenzen'
            },
            {
                name: 'Workshops',
                target: '/workshops'
            },
            {
                name: 'Über mich',
                target: '/ueber-mich'
            },
            {
                name: 'Webdesign mit WordPress',
                target: '/webdesign-mit-wordpress'
            },
            {
                name: 'Nachhaltigkeit',
                target: '/nachhaltigkeit'
            },
            {
                name: 'Kontakt',
                target: '/kontakt'
            },
        ]
    })
};
</script>
